<template>
  <button
    class="p-link layout-topbar-button ml-auto"
    @click="setToggleTicketPreview(!showTicketPreview)"
  >
    <img
      src="/images/header/ticket.gif"
      class="justify-content-center img-icon-ticket-preview"
    />
    <span class="total-ticket-preview">
      {{
        currentPlayData && currentPlayData.plays
          ? currentPlayData.plays.length
          : ""
      }}
    </span>
  </button>
  <Dialog
    header="CurrentPlayTicketPreview"
    class="ticket-preview-modal no_close_button p-dialog-maximized"
    :visible="showTicketPreview && $windowSize.width <= 835"
    :showHeader="false"
    :modal="true"
    :closable="true"
    :closeOnEscape="true"
    :maximizable="true"
    id="modal_box_mobile_ticket_preview"
  >
    <div class="flex justify-content-center flex-wrap">
      <ticket-preview ref="ticket_preview" />
    </div>
    <template #footer>
      <Button
        label="Cerrar"
        icon="pi pi-times"
        class="p-button-text button-ticket-preview"
        @click="setToggleTicketPreview(false)"
      />
    </template>
  </Dialog>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import TicketPreview from "@/components/ticketoffice/TicketPreview.vue";

export default {
  components: { TicketPreview },
  methods: {
    ...mapMutations("ticketoffice", ["setToggleTicketPreview"]),
  },
  computed: {
    ...mapState("ticketoffice", [
      "currentPlayData",
      "showTicketPreview",
    ]),
  },
};
</script>

<style scoped lang="scss">
.visible {
  visibility: visible;
}

.visible-hidden {
  visibility: hidden;
}

.img-icon-ticket-preview {
  width: 4rem;
}

.total-ticket-preview {
  background: #cc195a;
  width: 5rem;
  padding: 0.2rem;
  position: relative;
  top: 1px;
  right: 1px;
  display: block !important;
  border-radius: 5rem;
  color: #fff;
  box-shadow: 1px 1px 10px -4px #212121;
}

.box-ticket-preview {
  position: fixed;
  background: #FFF;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.button-ticket-preview {
  margin-bottom: 0.5rem;
}
</style>
<style lang="scss">
.ticket-preview-modal.p-dialog {
  :deep(.card-client) {
    padding: 0.5rem !important;
  }
  .p-dialog-content  {
    padding: 0.5rem;
  } 
  .p-component, div, b, span {
    font-size: 0.9rem !important;
  }
  .p-dialog-footer {
    border-radius: 0px;
  }
}
</style>